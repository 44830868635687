import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`

body {
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
   /* min-height: -webkit-fill-available; */
}
/*html {
  height: -webkit-fill-available;
}*/

html {
	-ms-text-size-adjust:100%;
	-webkit-text-size-adjust:100%;
}
/* Make images easier to work with */
img {
    width:100%;
    max-width:100%;
    display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
    font: inherit;
}
button{cursor:pointer;}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
    * {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}
*,:after,:before{box-sizing:border-box;
    font-family:inherit;
    color:inherit;
    overflow-wrap:break-word;
    margin:0;
    padding:0;
    border:0 solid}
[hidden]{display:none}
[hidden]+*{margin-top:0!important}
footer,header,main,nav{display:block}
@media (prefers-reduced-motion:no-preference){:root{scroll-behavior:smooth}
}
[tabindex="-1"]:focus{outline:none}
.vh{clip-path:inset(100%)!important;clip:rect(1px,1px,1px,1px)!important;height:1px!important;overflow:hidden!important;position:absolute!important;white-space:nowrap!important;width:1px!important}
abbr{text-decoration: none;}
strong{font-weight:bold}
p,li,h1,h2,h3,h4{word-break:break-word;}
ul{list-style:none;}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: var(--color-placeholder);
}
::-moz-placeholder { /* Firefox 19+ */
  color: var(--color-placeholder);
}
:-ms-input-placeholder { /* IE 10+ */
  color: var(--color-placeholder);
}
:-moz-placeholder { /* Firefox 18- */
  color: var(--color-placeholder);
}
::-moz-placeholder {
  opacity: 1;
}
`

export default GlobalStyle