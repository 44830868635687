import { createGlobalStyle } from 'styled-components'

const ThemeStyle = createGlobalStyle`
  
:root{
    --font-plain: 'Open Sans', sans-serif;
    --font-heading: 'Open Sans', sans-serif;
    --font-weight-normal: 400;
    --font-weight-medium: 600;
    --font-weight-bold: 700;
    
    --color-text-dark:#272727;
    --color-text-dark-alt:#353535;
    
    --color-text-light:#fff;
    --color-text-light-alt:#fefefe;

    --color-background-light-primary:#fff;
    --color-background-light-secondary:#f1f1f1;
    
    --color-background-dark-primary:#141414;
    --color-background-darkest-secondary:#242424;

    --color-primary:rgba(232, 26, 36, 1);
    --color-primary-background:rgba(232, 26, 36, 1);
    --color-primary-border:rgba(186, 21, 29, 1);
    --color-primary-hover:rgba(209, 23, 32, 1);
    --color-primary-background-hover:rgba(209, 23, 32, 1);
    --color-primary-border-hover:rgba(162, 18, 25, 1);
    --color-primary-background-active:rgba(162, 18, 25, 1);
    --color-primary-border-active:rgba(139, 16, 22, 1);

    --color-secondary:rgba(1, 89, 163, 1);
    --color-secondary-background:rgba(1, 89, 163, 1);
    --color-secondary-border:rgba(1, 71, 130, 1);
  	--color-secondary-hover:rgba(1, 80, 147, 1);
    --color-secondary-background-hover:rgba(1, 80, 147, 1);
    --color-secondary-border-hover:rgba(1, 62, 114, 1);
    --color-secondary-background-active:rgba(1, 62, 114, 1);
    --color-secondary-border-active:rgba(1, 53, 98, 1);
	--color-secondary-opacity:rgba(1, 89, 163, .75);
    
    --color-tertiary:rgba(73, 73, 73, 1);
    --color-tertiary-background:rgba(73, 73, 73, 1);
    --color-tertiary-border:rgba(58, 58, 58, 1);
    --color-tertiary-hover:rgba(66, 66, 66, 1);
    --color-tertiary-background-hover:rgba(66, 66, 66, 1);
    --color-tertiary-border-hover:rgba(51, 51, 51, 1);
    --color-tertiary-background-active:rgba(51, 51, 51, 1);
    --color-tertiary-border-active:rgba(44, 44, 44, 1);
    --color-tertiary-opacity:rgba(73, 73, 73, .75);

    --color-border-dark:rgba(150, 150, 150, 1);
    --color-border-light:rgba(204, 204, 204, 1);
    
    --color-link-light:var(--color-secondary);
    --color-link-dark:var(--color-secondary);
    --color-link-visited-light:var(--color-secondary-border);
    --color-link-visited-dark:var(--color-secondary-border);

    --color-white: #fff;
    --color-white-opacity-primary:rgba(255,255,255,0.5);
    --color-white-opacity-secondary:rgba(255,255,255,0.7);

    --color-black: #000;
	--color-black-opacity-primary:rgba(0,0,0,0.6);
    --color-black-opacity-secondary:rgba(0,0,0,0.4);
	--color-black-opacity-tertiary:rgba(0,0,0,0.8);
    
    --color-error:rgba(204, 0, 0, 1);
    --color-error-background:rgba(204, 0, 0, .05);
    --color-error-border:rgba(204, 0, 0, .75);

    --color-success:rgba(0, 122, 0, 1);
    --color-success-background:rgba(0, 122, 0, .05);
    --color-success-border:rgba(0, 122, 0, .75);
    
    --color-info:rgba(17, 17, 255, 1);
    --color-info-background:rgba(17, 17, 255, .05);
    --color-info-border:rgba(17, 17, 255, .75);
    
    --color-warning:rgba(255, 165, 0, 1);
    --color-warning-background:rgba(255, 165, 0, .05);
    --color-warning-border:rgba(255, 165, 0, .75);
    
    --color-focus-dark:var(--color-text-dark);
    --color-focus-light:var(--color-text-light);
  	--color-focus:rgba(60, 0, 0, 1);
  
	--box-shadow:rgb(0 0 0 / 15%) 0 var(--border-thin) 7px;
    
    --ratio:1.25;

	--s-6:calc(var(--s0)/var(--ratio)/var(--ratio)/var(--ratio)/var(--ratio)/var(--ratio)/var(--ratio));//4.194304
	--s-5:calc(var(--s0)/var(--ratio)/var(--ratio)/var(--ratio)/var(--ratio)/var(--ratio));//5.24288
    --s-4:calc(var(--s0)/var(--ratio)/var(--ratio)/var(--ratio)/var(--ratio));//6.5536
    --s-3:calc(var(--s0)/var(--ratio)/var(--ratio)/var(--ratio));//8.192
    --s-2:calc(var(--s0)/var(--ratio)/var(--ratio));//10.24
    --s-1:calc(var(--s0)/var(--ratio));//12.8
    --s0:1rem;//16
    --s1:calc(var(--s0)*var(--ratio));//20
    --s2:calc(var(--s0)*var(--ratio)*var(--ratio));//25
    --s3:calc(var(--s0)*var(--ratio)*var(--ratio)*var(--ratio));//31.25
    --s4:calc(var(--s0)*var(--ratio)*var(--ratio)*var(--ratio)*var(--ratio));//39.0625
	--s5:calc(var(--s0)*var(--ratio)*var(--ratio)*var(--ratio)*var(--ratio)*var(--ratio));//48.828125
	--s6:calc(var(--s0)*var(--ratio)*var(--ratio)*var(--ratio)*var(--ratio)*var(--ratio)*var(--ratio));//61.03515625

  
    --s0x2:calc(2*var(--s0));
  
    --measure-small:40ch;
    --measure:65ch;
    --measure-long:90ch;
    --measure-max:75em;

    --form-width-smallest: 20ch;
    --form-width-small: 30ch;
    --form-width: 40ch;
    --form-width-large: 50ch;
    --form-width-largest: 60ch;

    --line-height:calc(1.2*var(--ratio));
    --line-height-small: var(--ratio);
    
    --border-thinnest:calc(.0625*var(--s0));
    --border-thin:calc(.125*var(--s0));
    --border-medium:calc(.1875*var(--s0));
    --border-thick:calc(1.5*var(--s0));
    --border-radius: calc(.1875*var(--s0));
    --border-radius-inner: calc(.125*var(--s0));

	--layout-space-support: min(max(.5 * var(--s0), 2vw), var(--s0));
	--layout-space: clamp(.5 * var(--s0), 2vw, var(--s0));
	--content-space-support: min(max(.5 * var(--s0), 2vw), 1.5 * var(--s0));
	--content-space: clamp(.5 * var(--s0), 2vw, 1.5 * var(--s0));

	--font-size-small: calc(.875 * var(--s0));
  
    line-height:calc(1.2*var(--ratio));
  
	font-size: min(max(var(--font-size-small), 2.25vw), var(--s0));
	@supports (font-size: clamp(var(--font-size-small), 2.25vw, var(--s0))) {
		font-size: clamp(var(--font-size-small), 2.25vw, var(--s0));
	}
	
   
    font-family:var(--font-plain);

    background-color:var(--color-background-light);
    color:var(--color-text-dark);

  
  
}
a {
  color:var(--color-link-dark);
}

:focus {
  outline: var(--border-thin) solid var(--color-focus);
  outline-offset: var(--border-thin)
}
:focus:not(:focus-visible) {
  outline:none;
  outline-offset:0;
}
:focus-visible {
  outline: var(--border-thin) solid var(--color-focus);
  outline-offset: var(--border-thin)
}


h1, .h1{

  line-height:var(--line-height-small);
  font-family:var(--font-heading);
  font-weight:var(--font-weight-normal);
  color:var(--color-primary);
  font-size: var(--s3);
}
h2, .h2{
  line-height: var(--line-height-small);
  font-family:var(--font-heading);
  font-weight:var(--font-weight-normal);
  font-size: var(--s2);
}
h3, .h3{
  line-height: var(--line-height-small);
  font-family:var(--font-heading);
  font-weight:var(--font-weight-normal);
  font-size: var(--s1);
}
h4, .h4{
  line-height: var(--line-height-small);
  font-family:var(--font-heading);
  font-weight:var(--font-weight-normal);
  font-size: var(--s0);
}

body.menu-open {
  height: 100vh;
  overflow-y: hidden;
  position: fixed;
}
`

export default ThemeStyle