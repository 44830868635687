import React from 'react'
import GlobalStyle from 'parsley-core/src/theme/global'
import ThemeStyle from './theme'
import "../../theme/fonts.css"

export const Styles = ({ children }) => (
    <>
        <GlobalStyle />
        <ThemeStyle />
        {children}
    </>
)